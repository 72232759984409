import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../config';

const SEO = ({ title, description, image }) => {
  const siteTitle = title || config.seo.title;
  const metaDescription = description || config.seo.description;
  const ogImage = image || config.seo.ogImage;

  return (
    <Helmet>
      <html lang={config.meta.language} />
      <title>{siteTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={config.seo.keywords} />
      <meta name="author" content={config.seo.author} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={config.meta.siteUrl} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={ogImage} />

      {/* Social Links */}
      {config.social.instagram && (
        <link rel="me" href={`https://instagram.com/${config.social.instagram.replace('@', '')}`} />
      )}

      {/* Theme Color */}
      <meta name="theme-color" content={config.seo.themeColor} />
      
      {/* Verification */}
      {config.meta.googleSiteVerification && (
        <meta name="google-site-verification" content={config.meta.googleSiteVerification} />
      )}
    </Helmet>
  );
};

export default SEO; 