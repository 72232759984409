const config = {
  seo: {
    title: "puesa",
    description: "House music",
    keywords: "house music, techno, beatmaker, mksrec, puesa",
    author: "PUESA",
    ogImage: "puesa transparent logo.png", // Open Graph image for social sharing
    themeColor: "#000000",
  },
  social: {
    instagram: "@iampuesa",
  },
  meta: {
    language: "en",
    siteUrl: "https://puesa.com",
    googleSiteVerification: "", // Add your Google verification code here
  }
};

export default config; 