// src/App.js
import React from 'react';
import PlasmaEffect from './PlasmaEffect';
import SEO from './components/SEO';

function App() {
  return (
    <>
      <SEO />
      <PlasmaEffect />
    </>
  );
}

export default App;
