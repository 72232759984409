import React, { useEffect, useRef, useMemo } from 'react';

const PlasmaEffect = () => {
  const canvasRef = useRef(null);
  const SIN_TABLE_SIZE = 2048;

  const sinTable = useMemo(() => {
    const table = new Float32Array(SIN_TABLE_SIZE);
    for (let i = 0; i < SIN_TABLE_SIZE; i++) {
      table[i] = Math.sin((i / SIN_TABLE_SIZE) * 2 * Math.PI);
    }
    return table;
  }, []);

  const c64Palette = useMemo(
    () => [
      [0, 0, 0], // Black
      [255, 255, 255], // White
      [136, 0, 0], // Red
      [170, 255, 238], // Cyan
      [204, 68, 204], // Purple
      [0, 204, 85], // Green
      [0, 136, 255], // Light Blue
      [238, 238, 119], // Yellow
      [221, 136, 85], // Orange
      [102, 68, 0], // Brown
      [255, 119, 119], // Light Red
      [51, 51, 51], // Dark Grey
      [119, 119, 119], // Grey
      [170, 255, 102], // Light Green
      [0, 136, 255], // Light Blue
      [187, 187, 187], // Light Grey
    ],
    []
  );

  // Pre-calculate reciprocals and other constants
  const RECIPROCALS = useMemo(() => ({
    RECIP_16: 1.0 / 111.0,
    RECIP_9: 1.0 / 19.0,
    RECIP_8: 1.0 / 18.0,
    RECIP_13: 1.0 / 15.0,
    RECIP_2PI: 1.0 / (2 * Math.PI),
    RECIP_SIN_TABLE: 1.0 / SIN_TABLE_SIZE
  }), []);

  // Add these constants
  const PLASMA_CONSTANTS = useMemo(() => ({
    BASE_128: 128.0,
    MULT_128: 128.0,
    MULT_64: 64.0,
    COLOR_OFFSET_85: 85,
    COLOR_OFFSET_170: 170,
    ALPHA: 255
  }), []);

  useEffect(() => {
    const getClosestC64Color = (function () {
      const cache = {};

      return (r, g, b) => {
        const key = `${r},${g},${b}`;
        if (cache[key]) {
          return cache[key];
        }

        let closestColor = c64Palette[0];
        let closestDistance = Infinity;

        for (const color of c64Palette) {
          const distance = Math.sqrt(
            Math.pow(color[0] - r, 2) + Math.pow(color[1] - g, 2) + Math.pow(color[2] - b, 2)
          );

          if (distance < closestDistance) {
            closestDistance = distance;
            closestColor = color;
          }
        }

        cache[key] = closestColor;
        return closestColor;
      };
    })();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width = window.innerWidth;
    let height = window.innerHeight;

    const isPortrait = height > width;

    if (isPortrait) {
      [width, height] = [height, width]; // Swap width and height for portrait mode
    }

    const lowResWidth = 160;
    const lowResHeight = 100;
    const offscreenCanvas = new OffscreenCanvas(lowResWidth, lowResHeight);
    const offscreenCtx = offscreenCanvas.getContext('2d');

    let time = Math.random() * 33;
    let time2 = Math.random() * 100;
    let time3 = Math.random() * 50;
    let sqrtLUT = [];
    let delta = 0.02;
    let slowdown = 0.5;

    // Create LUTs for sine values
    const sinLUT = Array.from({ length: 256 }, (_, i) => Math.sin((i * Math.PI) / 128.0));
    const sinLUT6 = Array.from({ length: lowResHeight }, (_, y) => Math.sin(y / 6.0));

    // Define the colorCache object
    const colorCache = {};

    // Pre-calculate scale factors for logo
    const LOGO_SCALE_FACTOR = 0.8;
    const WOBBLE_AMPLITUDE = 0.05;
    const WOBBLE_SPEED = 1;
    
    // Pre-calculate animation speeds
    const TIME_SPEEDS = {
      BASE: slowdown * 0.05,
      TIME2: slowdown * 0.2,
      TIME3: slowdown * 0.11
    };

    const fastSin = (angle) => {
      angle = angle % (2 * Math.PI);
      if (angle < 0) angle += 2 * Math.PI;
      return sinTable[Math.floor(angle * RECIPROCALS.RECIP_2PI * SIN_TABLE_SIZE) & (SIN_TABLE_SIZE - 1)];
    };

    for (let x = 0; x <= lowResWidth; x++) {
      sqrtLUT[x] = [];
      for (let y = 0; y <= lowResHeight; y++) {
        sqrtLUT[x][y] = Math.sqrt(x * x + y * y);
      }
    }

    const colorMapLUT = new Uint32Array(256 * 256 * 256);
    // Pre-calculate all possible RGB combinations
    for (let r = 0; r < 256; r += 8) {
      for (let g = 0; g < 256; g += 8) {
        for (let b = 0; b < 256; b += 8) {
          const closest = getClosestC64Color(r, g, b);
          const index = (r << 16) | (g << 8) | b;
          colorMapLUT[index] = (255 << 24) | (closest[2] << 16) | (closest[1] << 8) | closest[0];
        }
      }
    }

    const drawPlasma = () => {
      const imageData = offscreenCtx.createImageData(lowResWidth, lowResHeight);
      const buf = new ArrayBuffer(imageData.data.length);
      const buf8 = new Uint8ClampedArray(buf);
      const data32 = new Uint32Array(buf);
      
      const timeFactors = {
        sin2: fastSin(time2),
        sin3: fastSin(time3)
      };

      for (let x = 0; x < lowResWidth; x++) {
        const xRecip16 = x * RECIPROCALS.RECIP_16;
        const xRecip9 = x * RECIPROCALS.RECIP_9;
        const xRecip13 = x * RECIPROCALS.RECIP_13;

        for (let y = 0; y < lowResHeight; y++) {
          const distance = sqrtLUT[x][y];
          const value = (PLASMA_CONSTANTS.BASE_128 +
            ((PLASMA_CONSTANTS.MULT_128 * (
              fastSin(xRecip16 + timeFactors.sin2) +
              fastSin(y * RECIPROCALS.RECIP_9 + timeFactors.sin3) +
              fastSin(xRecip9 + timeFactors.sin3) +
              fastSin((x + y) * RECIPROCALS.RECIP_16 + timeFactors.sin2) +
              fastSin(distance * RECIPROCALS.RECIP_8 + timeFactors.sin3) +
              fastSin(xRecip13 + timeFactors.sin2)
            )) | 0) +
            ((PLASMA_CONSTANTS.MULT_64 * sinLUT6[y]) | 0)) | 0;

          const sinIndex = value & 255;
          const sinValue = sinLUT[sinIndex];
          const red = Math.floor(128 + 127 * sinValue);
          const green = Math.floor(128 + 127 * sinLUT[(sinIndex + 85) & 255]);
          const blue = Math.floor(128 + 127 * sinLUT[(sinIndex + 170) & 255]);

          const colorKey = `${red},${green},${blue}`;
          const closestColor = colorCache[colorKey] || (colorCache[colorKey] = getClosestC64Color(red, green, blue));

          const index = y * lowResWidth + x;
          data32[index] = 
            (255 << 24) |                // alpha
            (closestColor[2] << 16) |    // blue
            (closestColor[1] << 8) |     // green
            closestColor[0];             // red
        }
      }
      
      imageData.data.set(buf8);
      offscreenCtx.putImageData(imageData, 0, 0);

      if (logo.complete) {
        const logoScale = Math.min(
          (isPortrait ? lowResHeight : lowResWidth) / logo.width,
          (isPortrait ? lowResWidth : lowResHeight) / logo.height
        ) * LOGO_SCALE_FACTOR;

        const logoWidth = logo.width * logoScale;
        const logoHeight = logo.height * logoScale;
        
        offscreenCtx.save();
        const wobbleX = Math.sin(time * WOBBLE_SPEED) * WOBBLE_AMPLITUDE;
        const wobbleY = Math.cos(time * WOBBLE_SPEED) * WOBBLE_AMPLITUDE;
        
        if (isPortrait) {
          // Pre-calculate positions
          const centerX = (lowResHeight - logoWidth) * 0.5;
          const centerY = (lowResWidth - logoHeight) * 0.5;
          
          offscreenCtx.translate(lowResWidth * 0.5, lowResHeight * 0.5);
          offscreenCtx.rotate(-Math.PI * 0.5);
          offscreenCtx.translate(-lowResHeight * 0.5, -lowResWidth * 0.5);
          
          offscreenCtx.translate(centerX + logoWidth * 0.5, centerY + logoHeight * 0.5);
          offscreenCtx.transform(1, wobbleY, wobbleX, 1, 0, 0);
          offscreenCtx.translate(-(centerX + logoWidth * 0.5), -(centerY + logoHeight * 0.5));
          
          offscreenCtx.drawImage(logo, centerX, centerY, logoWidth, logoHeight);
        } else {
          const logoX = (lowResWidth - logoWidth) * 0.5;
          const logoY = (lowResHeight - logoHeight) * 0.5;
          
          offscreenCtx.translate(logoX + logoWidth * 0.5, logoY + logoHeight * 0.5);
          offscreenCtx.transform(1, wobbleY, wobbleX, 1, 0, 0);
          offscreenCtx.translate(-(logoX + logoWidth * 0.5), -(logoY + logoHeight * 0.5));
          
          offscreenCtx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);
        }
        offscreenCtx.restore();
      }

      time += TIME_SPEEDS.BASE;
      time2 += TIME_SPEEDS.TIME2 * delta;
      time3 += TIME_SPEEDS.TIME3 * delta;
      delta = 0.05;
    };

    const logo = new Image();
    logo.src = 'puesa transparent logo.png';

    let animationFrameId;

    const animate = (currentTime) => {
      drawPlasma();
      
      // Update canvas dimensions to match screen
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      width = window.innerWidth;
      height = window.innerHeight;
      
      ctx.clearRect(0, 0, width, height);
      ctx.imageSmoothingEnabled = false;
      
      if (isPortrait) {
        ctx.save();
        ctx.translate(width/2, height/2);
        ctx.rotate(Math.PI/2);
        
        // Scale to cover the entire screen while maintaining aspect ratio
        const scaleX = width / lowResHeight;
        const scaleY = height / lowResWidth;
        const scale = Math.max(scaleX, scaleY);
        
        const scaledWidth = lowResHeight * scale;
        const scaledHeight = lowResWidth * scale;
        
        ctx.drawImage(
          offscreenCanvas,
          -scaledHeight/2,
          -scaledWidth/2,
          scaledHeight,
          scaledWidth
        );
        ctx.restore();
      } else {
        // Scale to cover the entire screen while maintaining aspect ratio
        const scaleX = width / lowResWidth;
        const scaleY = height / lowResHeight;
        const scale = Math.max(scaleX, scaleY);
        
        const scaledWidth = lowResWidth * scale;
        const scaledHeight = lowResHeight * scale;
        
        // Center the plasma
        const xOffset = (width - scaledWidth) / 2;
        const yOffset = (height - scaledHeight) / 2;
        
        ctx.drawImage(
          offscreenCanvas,
          xOffset,
          yOffset,
          scaledWidth,
          scaledHeight
        );
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    logo.onload = () => {
      animate();
    };

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [c64Palette, sinTable, RECIPROCALS, PLASMA_CONSTANTS]);

  return (
    <canvas 
      ref={canvasRef} 
      style={{ 
        display: 'block',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        imageRendering: 'pixelated',
        backgroundColor: 'black'
      }} 
    />
  );
};

export default PlasmaEffect;
